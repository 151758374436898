import React from 'react'
import { images } from 'config/images'

export const ApplicationDevProps = {
  bannerProps: {
    bannerHeading: (
      <>
        Application development services{' '}
        <span className="heading-red">to accelerate your business growth</span>{' '}
      </>
    ),
    bannerContent: (
      <>
        Our next-gen app development offering simplifies business complexities
        for some of the most successful companies
      </>
    ),
    BannerAlt: 'Application development services',
    buttonName: 'Contact us now',
    buttonLink: '/contact/',
  },
  buildApplicationProps: {
    buildApplicationHeading: (
      <>
        Why Simform for <br />
        <span className="highlight-head">Application</span>{' '}
        <span className="highlight-head">Development?</span>
      </>
    ),
    headDetails: [
      {
        Para: (
          <>
            We are a web and mobile app development company focused on building
            quality software products. Our dedicated development teams are
            experts in numerous technology stacks. They serve as specialists in
            merging creative designs with next-gen app development services. We
            help you to build consumer-facing or B2B apps for a range of digital
            platforms like mobile, web, desktop, smartwatch, VR and AR.
            <br /> <br />
            We build fully functional, robust, and scalable software products
            that withstand the test of time. Our application development
            services include technology consultation, development, testing,
            support and maintenance. Our client success stories include audit
            and improvement of existing apps and modernisation of legacy apps.
          </>
        ),
      },
      {
        detailsList: [
          'Optimal resource utilization',
          'Close attention to user experience',
          'Extremely collaborative and outcome-focused',
          'Guaranteed application scaling on demand',
          'Strict adherence to transparency and reporting',
          'Consistent delivery by following CI/CD approaches',
          'Rigorous quality assurance testing before going live',
          'Proven track record in building successful MVP',
        ],
      },
    ],
  },
  clientLogoPrpos: {
    clientLogoHeading: (
      <>
        Trusted by <span className="color-through">900+ happy clients</span>{' '}
        including these Fortune companies
      </>
    ),
    clientLogos: [
      {
        clientLogosrc: images.redbull,
        clientLogosrcg: images.redbullg,
        clientLogoAlt: 'Redbull',
      },
      {
        clientLogosrc: images.FujiFilmGray,
        clientLogosrcg: images.fujiFilmLogo,
        clientLogoAlt: 'Fuji Film',
      },
      {
        clientLogosrc: images.renesaslogoGray,
        clientLogosrcg: images.renesasLogo,
        clientLogoAlt: 'Renesas',
      },
      {
        clientLogosrc: images.Hyundai_Glovis_logoGray,
        clientLogosrcg: images.hyundaiGlovisLogo,
        clientLogoAlt: 'Hyundai Glovis',
      },
      {
        clientLogosrc: images.boag,
        clientLogosrcg: images.boa,
        clientLogoAlt: 'Bank of America',
      },
      {
        clientLogosrc: images.ciscog,
        clientLogosrcg: images.cisco,
        clientLogoAlt: 'Cisco',
      },
      {
        clientLogosrc: images.mitsubishilogoGray,
        clientLogosrcg: images.mitsubishiLogo,
        clientLogoAlt: 'Mitsubishi Electric',
      },
      {
        clientLogosrc: images.TrygGray,
        clientLogosrcg: images.trygCLogo,
        clientLogoAlt: 'Tryg',
      },
      {
        clientLogosrc: images.cameog,
        clientLogosrcg: images.cameo,
        clientLogoAlt: 'Cameo',
      },
      {
        clientLogosrcg: images.fihCLogo,
        clientLogosrc: images.FIHGray,
        clientLogoAlt: 'Fih',
      },
      {
        clientLogosrc: images.HiltonGray,
        clientLogosrcg: images.hiltonLogo,
        clientLogoAlt: 'Hilton',
      },
      {
        clientLogosrc: images.kirloskarlogoGray,
        clientLogosrcg: images.kirloskarBrothersLogo,
        clientLogoAlt: 'kirloskar Brothers',
      },
    ],
    clientCount: '900+',
    clienttitle: 'Happy Clients',
  },
  serviceofferingsProps: {
    title: (
      <>
        Application Development{' '}
        <span className="highlight-head">Offerings</span>
      </>
    ),
    Serviceofferings: [
      {
        Title: 'Web Application Development',
        para:
          'We offer end-to-end web application development services which include designing, prototyping, building, and migrating to new web architecture. Our web design and development experts are tech-savvy enthusiasts who focus on creating custom web apps. We build apps that are secure, scalable, and responsive.',
        typeList: [
          {
            title: 'Web Design & Development',
            para:
              'We focus on building web apps and websites that are visually appealing with user-centric UI/UX designs that are secure, database friendly, and CMS friendly.',
          },
          {
            title: 'SaaS Product Development',
            para:
              'Our end-to-end approach to product development enables us to build a complete SaaS solution that includes web apps, APIs, cloud hosting and data storage.',
          },
          {
            title: 'Progressive Web Apps (PWA)',
            para:
              'We bring both the web and mobile users under one PWA with optimized UX, customized interface, and leveraging app shell model to build native-like features.',
          },
        ],
        buttonLink: '/services/web-apps-development/',
        buttonName: 'LEARN MORE',
        targetBlank: true,
      },
      {
        Title: 'Mobile App Development',
        para:
          'Be it native or hybrid platform, we are a mobile app development company that offers a complete cycle of mobile app design, integration, and development services. We provide flexible engagement models to all startups to enterprises and our developers build mobile apps that improve user engagement.',
        typeList: [
          {
            title: 'iOS App Development',
            para:
              'We are experts in producing fully-fledged iOS mobile apps that are stable, scalable, and compatible with iPhone, iPad, Apple TV, and Apple Watches.',
          },
          {
            title: 'Android App Development',
            para:
              'Our android app developers create native apps with robust and evolutionary architecture using all trending technologies.',
          },
          {
            title: 'Cross-Platform App Development',
            para:
              'We build versatile cross-platform and hybrid apps that help you reduce investment costs with a single code base and accelerated time to market.',
          },
        ],
        buttonLink: '/services/mobile-app-development/',
        buttonName: 'LEARN MORE',
        targetBlank: true,
      },
      {
        Title: 'Other App Development services',
        para:
          'We also deliver specific services which are an integral part of the end-to-end app development process. Incremental change through these services helps in building a robust product. We can develop these solutions from a clean slate or augment existing applications for scalability and better performance.',
        typeList: [
          {
            title: 'Desktop Application Development',
            para:
              'Our desktop application services offer a wide range of apps that may focus on utility, productivity, multimedia, and more.',
          },
          {
            title: 'UI/UX Design and Development',
            para:
              'Great UX starts with you and your audience. Our research-based design approach ensures the success of your users.',
          },
          {
            title: 'Application Maintenance & Support Services',
            para:
              'We conduct periodical quality testing and maintenance to identify bottlenecks and find areas of improvement and after-sales support to ensure there are no security vulnerabilities.',
          },
        ],
        buttonLink: '/contact/',
        buttonName: 'Get In Touch',
      },
    ],
  },
  customDevProps: {
    serviceHeading: (
      <>
        Application Development in
        <br />
        <span className="highlight-head">Cutting-edge Technologies</span>
      </>
    ),
    serviceContents: [
      {
        id: '1',
        serviceHeading: 'Big Data',
        serviceParagraph: (
          <>
            We use modern databases such as Hadoop, Cassandra, MongoDB, etc. to
            deploy huge volume of data to the cloud which improves app
            performance with reduced cost.
          </>
        ),
        serviceIcon: images.scloudApp,
        serviceAlt: 'Mobile',
        serviceClass: 'very-light-pink',
      },
      {
        id: '2',
        serviceHeading: 'Internet of Things (IoT)',
        serviceParagraph: (
          <>
            We provide end-to-end solutions on{' '}
            <strong>generating connected apps</strong> and the creation of smart
            enterprise apps by embracing trending languages and technologies.
          </>
        ),
        serviceIcon: images.sInternetofThings,
        serviceAlt: 'Mobile',
        serviceClass: 'pale-grey',
      },
      {
        id: '3',
        serviceHeading: 'Artificial Intelligence (AI)',
        serviceParagraph: (
          <>
            We transform{' '}
            <strong>AI visions into tangible business results</strong> to
            empower app automation by leveraging next-gen technologies like
            TensorFlow, PyTorch, Anaconda, Scikit, etc.
          </>
        ),
        serviceIcon: images.sartificialIntelligence,
        serviceAlt: 'Manual',
        serviceClass: 'ice-blue',
      },
      {
        id: '4',
        serviceHeading: 'VR and AR app development',
        serviceParagraph: (
          <>
            We offer the power of extended realities to{' '}
            <strong>deliver innovative applications</strong> and experiences
            that were not possible before using the latest technologies.
          </>
        ),
        serviceIcon: images.svrArAppDev,
        serviceAlt: 'Software',
        serviceClass: 'pale',
      },
      {
        id: '5',
        serviceHeading: 'Machine Learning',
        serviceParagraph: (
          <>
            Our experts boost app productivity at scale to{' '}
            <strong>accelerate app operations</strong> via supervised and
            unsupervised learning using trending tools.
          </>
        ),
        serviceIcon: images.smachineLearning,
        serviceAlt: 'Devops',
        serviceClass: 'pale-lavender',
      },
      {
        id: '6',
        serviceHeading: 'Blockchain',
        serviceParagraph: (
          <>
            We leverage Blockchain-as-a-Service (BaaS) to{' '}
            <strong>decentralize applications</strong> and create distributed
            ledgers and dApps with promising technologies.
          </>
        ),
        serviceIcon: images.sblockchain,
        serviceAlt: 'Performance',
        serviceClass: 'very-light-pink-three',
      },
    ],
  },
  caseStudyProps: {
    caseStudyheading: (
      <>
        Case-studies on How Our{' '}
        <span className="highlight-head">Custom Developed Apps</span> Helped
        These Companies
      </>
    ),
    caseStudiesContents: [
      {
        caseStudiesTitle:
          'Building EV charging platform to revolutionize the way we power',
        caseStudiesParaGraph:
          '…clean and quiet on-site power to substitute or supplement the use of diesel generators for a variety of remote power needs…',
        caseStudiesClassName: 'duck-egg-blue',
        caseStudiesImgAlt: 'Mobile Mockup',
        caseURL: '/case-studies/freewire-scalable-vehicle-charging/',
      },
      {
        caseStudiesTitle:
          'Improving the online web experience for International Hockey Federation (FIH)',
        caseStudiesParaGraph:
          '…increased user engagement, usability, discoverability, while providing modern user experience (UX)…',
        caseStudiesClassName: 'light-peach',
        caseStudiesImgAlt: 'Safari',
        caseURL: '/case-studies/fih/',
      },
      {
        caseStudiesTitle: 'Helping luxury shoe brand go digital',
        caseStudiesParaGraph:
          "…take customized orders by applying various combinations of fabrics, motifs, and monograms based on the customer's preferences…",
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Ipad',
        caseURL: '/case-studies/stubbs-woottons/',
      },
      {
        caseStudiesTitle: 'Helping retailers modernize shopping experience',
        caseStudiesParaGraph:
          '…solving the multi-billion dollar problem of faster checkouttimes, customer loyalty programs, and deeper analytics...',
        caseStudiesClassName: 'light-grey-two',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/swift-shopper-retail-checkout/',
      },
      {
        caseStudiesTitle: 'Shortcut to get featured on Redbull website',
        caseStudiesParaGraph:
          '...custom scalable solution that can easily accommodate their event engagement needs…',
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/redbull-photobooth/',
      },
    ],
  },
  customReviewSliderProps: {
    heading: (
      <>
        What <span className="highlight-head">Customers Say</span>
        <br />
        About Our Services
      </>
    ),
    textTestimonialProps: [
      {
        clientName: 'Danielle Beben',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'The communication line was always open and they are very approachable',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346088120',
      },
      {
        clientName: 'Jawann Swislow',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'They took care of design and even architecture side as well',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087960',
      },
      {
        clientName: 'Justin Wollman',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'Simform has variety of engineers to meet our current and future demands',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087717',
      },
      {
        clientName: 'Olivia Butters',
        clientDesignation: 'Product Designer',
        clientTestimonial: 'We needed someone who could grasp our vision',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087638',
      },
      {
        clientName: 'Ritwik Pavan',
        clientDesignation: 'Product Designer',
        clientTestimonial: 'I have been thrilled to have such a dedicated team',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/346087564',
      },
      {
        clientName: 'Jeff Petreman',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'Simform is real asset to the TransAction Logistics team.',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/346087854',
      },
      {
        clientName: 'Andy Gibson',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'They’ve always been able to come up with solution through technology.',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/368290760',
      },
    ],
  },

  workingBenefitsProps: {
    workingBenefitsHeading: (
      <>
        Benefits of Working with
        <br /> <span className="highlight-head">Simform</span>
      </>
    ),
    processPara: `Focus on core mobile and web application development 
    right away! We will take care of the rest to enforce security, autoscaling
    abilities, and distributing app workload capabilities to your application.`,
    workingBenefitsProps: [
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle:
          'Maximizing ROI of your mission-critical IT architecture',
        workingBenefitsContent: (
          <>
            Simform develops and supports mission-critical applications for some
            of the world’s most successful companies.
          </>
        ),
        benefitImgAlt: 'ROI of application development',
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsContent: (
          <>
            Whether you need an application from ground-up or help salvaging an
            underperforming application, we can jump in and develop solutions in
            a way that gives you options not available “off-the-shelf”. Using
            proven development methodologies, tools, and managed services our
            global experts can help you improve your time to market and reduce
            risk.
          </>
        ),
        benefitImgAlt: 'App development',
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle: 'Rapid application delivery using Agile',
        workingBenefitsContent: (
          <>
            Today's customers need improved speed, quality and reliability of
            applications. Our Agile practice helps you execute multi-platform
            applications with faster iterations, faster delivery times, and with
            higher quality.
          </>
        ),
        benefitImgAlt: 'Rapid app development',
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle:
          'Microservices architecture for independent resilience',
        workingBenefitsContent: (
          <>
            We work with Microservices architecture where application is split
            into independent units based on business functions, allowing for
            continuous deployment, increased performance, and partial updates
            without a complete stop.
          </>
        ),
        benefitImgAlt: 'Microservices architecture',
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'blue-gradient',
        workingBenefitsTitle:
          'Architecture that perfectly responds to your goals',
        workingBenefitsContent: (
          <>
            We also use Reactive architecture where creating resilient and
            flexible applications that elegantly handle ‘live’ data, high load,
            and multi-userness and bring impeccable UX with its high
            responsiveness on top.
          </>
        ),
        benefitImgAlt: 'Architecture for web app development',
      },
    ],
  },

  developSoftAgileProps: {
    heading: (
      <>
        How We Develop
        <br /> Apps <span className="highlight-head">Using Agile</span>
      </>
    ),
    headingLeftImgProps: [
      {
        src: images.serviceUser2,
        srcSet: images.serviceUser22x,
      },
      {
        src: images.serviceUser3,
        srcSet: images.serviceUser32x,
      },
      {
        src: images.serviceUser1,
        srcSet: images.serviceUser12x,
      },
    ],
    headingRightImgProps: [
      {
        src: images.serviceUser4,
        srcSet: images.serviceUser42x,
      },
      {
        src: images.serviceUser5,
        srcSet: images.serviceUser52x,
      },
      {
        src: images.serviceUser6,
        srcSet: images.serviceUser62x,
      },
    ],
    workFlowFirstRows: [
      {
        workFlowClass: 'very-light-blue',
        workFlowCount: '1',
        workFlowIcon: images.ServiceSprintPlanning,
        workFlowIconAlt: 'Team',
        workFlowTitle: 'Sprint planning',
        workFlowParagraph:
          'Sprint roadmap is a collective planning effort. Team members collaborate to clarify items and ensure shared understanding.',
        workFlowArrowImg: images.iconFlowArrow1,
      },
      {
        workFlowClass: 'duck-egg-blue',
        workFlowCount: '2',
        workFlowIcon: images.ServiceTechArchitecture,
        workFlowIconAlt: 'Sprint',
        workFlowTitle: 'Tech architecture',
        workFlowParagraph:
          'We break monolithic apps into microservices. Decoupling the code allows teams to move faster and more independently.',
        workFlowArrowImg: images.iconFlowArrow2,
      },
      {
        workFlowClass: 'light-peach',
        workFlowCount: '3',
        workFlowIcon: images.ServiceStandups,
        workFlowIconAlt: 'Tech',
        workFlowTitle: 'Stand-ups & weekly demos',
        workFlowParagraph:
          'Stand ups, weekly demos, and weekly reviews make sure everyone is on the same page and can raise their concerns.',
        workFlowArrowImg: images.iconFlowArrow3,
      },
      {
        workFlowClass: 'light-peach-three',
        workFlowCount: '4',
        workFlowIcon: images.ServiceCodeReviews,
        workFlowIconAlt: 'StandUps',
        workFlowTitle: 'Code reviews',
        workFlowParagraph:
          'Code reviews before release help detect issues like memory leaks, file leaks, performance signs, and general bad smells.',
        workFlowArrowImg: images.iconFlowArrow4,
      },
    ],
  },
  industriesServeProps: {
    heading: (
      <>
        <span className="highlight-head">Industries</span> We Serve
      </>
    ),
    tabContents: [
      {
        tabName: 'Travel',
        contentHeading: 'Mobile bus ticketing app',
        contentPara:
          'Provide seamless travel app services with a  Multilingual Bus Ticket Booking App that provides estimated arrival time, ratings, reviews, real-time tracking securely, and multi-payment features. ',
        contentSingleProps: [
          {
            singleblkImg: images.mobilebus1,
            singleblkImg2x: images.mobilebus12x,
          },
          {
            singleblkImg: images.mobilebus2,
            singleblkImg2x: images.mobilebus22x,
          },
          {
            singleblkImg: images.mobilebus3,
            singleblkImg2x: images.mobilebus32x,
          },
        ],
      },
      {
        tabName: 'Finance',
        contentHeading: 'Mobile banking application',
        contentPara:
          'Provide flexibility to your customers with personal finance management, scheduled money transfer, Mobile RDC along with loyalty program integration.',
        contentSingleProps: [
          {
            singleblkImg: images.mobbanking1,
            singleblkImg2x: images.mobbanking12x,
          },
          {
            singleblkImg: images.mobbanking2,
            singleblkImg2x: images.mobbanking22x,
          },
          {
            singleblkImg: images.mobbanking3,
            singleblkImg2x: images.mobbanking32x,
          },
        ],
      },
      {
        tabName: 'Media & Entertainment',
        contentHeading: 'Music streaming app',
        contentPara:
          'A Music Streaming App with AI-based recommendation engine, push notifications, live streaming, radio stations integration and user behaviour tracking.',
        contentSingleProps: [
          {
            singleblkImg: images.mediastream1,
            singleblkImg2x: images.mediastream12x,
          },
          {
            singleblkImg: images.mediastream2,
            singleblkImg2x: images.mediastream22x,
          },
          {
            singleblkImg: images.mediastream3,
            singleblkImg2x: images.mediastream32x,
          },
        ],
      },
      {
        tabName: 'Software',
        contentHeading: 'B2B review platform',
        contentPara:
          'A tailor-made software app development service to build B2B Review & Research Mobile Application that serves as a single touch-point for businesses to find and rate other companies.',
        contentSingleProps: [
          {
            singleblkImg: images.b2b1,
            singleblkImg2x: images.b2b12x,
          },
          {
            singleblkImg: images.b2b2,
            singleblkImg2x: images.b2b22x,
          },
          {
            singleblkImg: images.b2b3,
            singleblkImg2x: images.b2b32x,
          },
        ],
      },
      {
        tabName: 'Healthcare',
        contentHeading: 'Doctor-on-demand app',
        contentPara:
          'Pull the advantage of healthcare app development to build a centralized telemedicine platform for patients to find doctors, read ratings, reviews, schedule & manage appointments for non-emergency services. ',
        contentSingleProps: [
          {
            singleblkImg: images.doctor1,
            singleblkImg2x: images.doctor12x,
          },
          {
            singleblkImg: images.doctor2,
            singleblkImg2x: images.doctor22x,
          },
          {
            singleblkImg: images.doctor3,
            singleblkImg2x: images.doctor32x,
          },
        ],
      },
      {
        tabName: 'NGO',
        contentHeading: 'Public health records app',
        contentPara:
          'm-Health mobile control application to effectively manage public health records using predictive analysis and real-time data communication.',
        contentSingleProps: [
          {
            singleblkImg: images.publich1,
            singleblkImg2x: images.publich12x,
          },
          {
            singleblkImg: images.publich2,
            singleblkImg2x: images.publich22x,
          },
          {
            singleblkImg: images.publich3,
            singleblkImg2x: images.publich32x,
          },
        ],
      },
      {
        tabName: 'Education',
        contentHeading: 'E-learning application',
        contentPara:
          'e-Learning Mobile App with user-centred design standards that uses a virtual reality environment for content delivery while maintaining high data security.',
        contentSingleProps: [
          {
            singleblkImg: images.educationElearning1,
            singleblkImg2x: images.educationElearning12x,
          },
          {
            singleblkImg: images.educationElearning2,
            singleblkImg2x: images.educationElearning22x,
          },
          {
            singleblkImg: images.educationElearning3,
            singleblkImg2x: images.educationElearning32x,
          },
        ],
      },
      {
        tabName: 'Retail',
        contentHeading: 'Mobile e-commerce app',
        contentPara:
          'A dedicated e-Commerce mobile app with product catalogues, advanced search results, online payment and integrated loyalty programs. ',
        contentSingleProps: [
          {
            singleblkImg: images.mobecomm1,
            singleblkImg2x: images.mobecomm12x,
          },
          {
            singleblkImg: images.mobecomm2,
            singleblkImg2x: images.mobecomm22x,
          },
          {
            singleblkImg: images.mobecomm3,
            singleblkImg2x: images.mobecomm32x,
          },
        ],
      },
    ],
  },
  AboutprojectformProps: {
    title: (
      <>
        Tell Us About <span className="highlight-head">Your Project</span>
      </>
    ),
    formTitleL: 'Request a Free Quote',
    formInfo: 'Guaranteed response within one business day.',
    formDetailstitle: 'What will happen next?',
    formButtonText: 'LET"S GET STARTED',
    list: [
      'We’ll reach out to you within 24 hours.',
      'We’ll discuss your project and gather your requirements and business objectives, and develop a proposal accordingly.',
      'You can start 15-days risk-free trial with us.',
    ],
  },
  awardsRecognitionProps: {
    heading: 'Awards & Recognitions',
    awardBlockProps: [
      {
        awardBlockImg: images.mspDataAiAzure2x,
      },
      {
        awardBlockImg: images.mspDigitalAppInnovationAzure,
      },
      {
        awardBlockImg: images.mspInfrastructureAzure,
      },
      {
        awardBlockImg: images.databricksLogo,
      },
      {
        awardBlockImg: images.cmmiLevel3,
      },
      {
        awardBlockImg: images.theAmericanBusinessAwards,
      },
      {
        awardBlockImg: images.globalOutsourcing100,
      },
      {
        awardBlockImg: images.greatPlaceToWork,
      },
      {
        awardBlockImg: images.glassdoorLogo2x,
      },
    ],
  },
  SimformGuaranteeData: {
    title: (
      <>
        Simform <span className="highlight-head">Guarantee</span>{' '}
      </>
    ),
    buildVid: images.demoVideo,
    posterAlt: 'Concepting',
    lightboxImgVid: 'Rsk2-XQhCAk',
    ButtonText: 'Work with us',
    Link: '/contact/',
    GuaranteeList: [
      {
        icon: images.sgFlexibleIcon,
        guaranteeTitle: 'Flexible, efficient scaling',
        guaranteeInfo: 'Expand or reduce your remote team size on demand.',
      },
      {
        icon: images.sgTeamsIcon,
        guaranteeTitle: 'Team of 1,000+ engineers',
        guaranteeInfo:
          'Access vetted experts in the tech stack of your choice.',
      },
      {
        icon: images.sgLongTermIcon,
        guaranteeTitle: 'Long-term predictability',
        guaranteeInfo: 'Simform’s average engagement duration is 2 years.',
      },
    ],
  },
  faqSectionProps: {
    heading: 'FAQs',
    faqProps: [
      {
        faqTitle: 'How do you design software applications? ',
        faqPara: (
          <>
            Even the most visually amazing app can and will fail to perform well
            on the app store if it’s not intuitive and lacks usability.
            <br /> <br />
            As a user, you would want to open an application and feel like you
            already intuitively know everything — where to click next, what
            actions to perform, how to jump to a particular place, etc. The
            second you get stuck, you give up and go try another app. Sounds
            familiar?
            <br /> <br />
            So what do we do to make sure this doesn’t happen to the potential
            users you just spent so much time researching? As a critical part of
            our app development services, we do UX.
            <br /> <br />
            Let’s get into some of the UX design processes.
            <br /> <br />
            Step 1: Information Architecture
            <br />
            Step 2: Wireframing your App
            <br />
            Step 3: Clickable prototype with UI
            <br /> <br />
            UX design is an ongoing process. With each update, you should
            consider the way people are using your app.
          </>
        ),
      },
      {
        faqTitle: 'How do you choose the application architecture?',
        faqPara: (
          <>
            We always follow the best practices for developing an app. Hence we
            keep these things in mind while choosing an application
            architecture.
            <br /> <br />
            <strong>Portability</strong>: The good application architecture
            ensures the system to be portable enough to respond to environmental
            changes keeping the impact of change at minimal level.
            <br /> <br />
            <strong>Maintainability</strong>: A good Application Architecture
            ensures the high maintainability of the mobile application. It
            reduces the efforts and complexity of the change implementation
            which is in favor of the developers.
            <br /> <br />
            <strong>Manageability</strong>: How efficiently and easily a
            software system can be monitored and maintained to keep the system
            performing, secure, and running smoothly.
            <br /> <br />
            <strong>Reusability</strong>: Good Architecture always considers the
            reusability aspect during design of components and protocols.
            <br /> <br />
            <strong>Testability</strong>: Good application architecture ensures
            that each component is separately testable.
            <br /> <br />
            <strong>Security</strong>: The application architecture should be
            robust enough to secure the data consumed by the application.
            <br /> <br />
            <strong>Performance</strong>: A mobile application architecture
            should ensure to meet the performance expectations of the users.
          </>
        ),
      },
      {
        faqTitle: 'What does your app development process look like?',
        faqPara: (
          <>
            Here’s how we will work with you for application development:
            <br /> <br />
            <strong>
              1) Test Cases Driven User Stories for Clear Requirements -{' '}
            </strong>
            <br /> <br />
            When our engagement with the client starts we help them create
            detailed user stories and scope out the next few sprints.
            <br /> <br />
            <strong>2) Intense Sprint Planning</strong>
            <br /> <br />
            In order to execute projects on time, you need to plan sprints as
            much as possible.
            <br /> <br />
            <strong>3) Iterative Delivery</strong>
            <br /> <br />
            Typically, in an agile development process, we will divide the
            implementation process into several checkpoints rather than a single
            deadline. They are called iterations and are part of each sprint.
            <br /> <br />
            <strong>4) Documentation</strong>
            <br /> <br />
            We document everything from application and code level architecture
            decisions, to live user stories document, to design focused
            UX/UI/Design System documents.
            <br /> <br />
            <strong>5) Constant Communication and Retrospective</strong>
            <br /> <br />
            Stand-Up Each workday begins with a brief team stand-up meeting to
            discuss what we did yesterday, and what we plan to do today.
            <br /> <br />
            <strong>6) Code review</strong>
            <br /> <br />
            A successful peer review strategy for code review requires balance
            between strictly documented processes and a non-threatening,
            collaborative environment.
            <br /> <br />
            <strong>7) Integrating QA in the development process</strong>
            <br /> <br />
            We have gotten much better about integrating QA in our development
            process. We used to loop QA in after our code was pushed to the
            master branch, which was clunky.
            <br /> <br />
            Now our QA engineers will identify and create a test plan as soon as
            we begin working on the next sprint. As initial development nears
            completion, an engineer and a QA engineer will run through test
            scenarios together. <br /> <br />
            <strong>8) CI CD and Automating Deployment</strong>
            <br /> <br />
            Easily release and deploy solutions by making the build process
            easier by building CI CD pipeline. The developer's changes are
            validated by creating a build and running automated tests against
            the build.
            <br /> <br />
            <strong>9) Post-release</strong>
            <br /> <br />
            It’s important to circle back and review how the process went once
            you’re done, be it a success or failure.
            <br /> <br />
            Did the testing correctly model the production scenario? Did the
            team correctly estimate the effort required to release a product?
            How is the product running in production?
            <br /> <br />
            Review how well the team performed by revisiting the implementation
            and testing checkpoints. Your team will adjust their performance
            accordingly as they grow used to being held accountable for every
            step in this process.
          </>
        ),
      },

      {
        faqTitle: 'How much does it cost to build an app?',
        faqPara: (
          <>
            The cost of App development depends on many factors such as :
            <br /> <br />
            <strong>Development platform</strong> - web app, native, hybrid,
            cross-platform
            <br />
            <strong>Development platform</strong> - web app, native, hybrid,
            cross-platform
            <br />
            <strong>App Complexities</strong> - number of screens, limited data,
            need for API connection, admin console, user profiles <br />
            <strong>Resource location</strong> - Orlando or other cities in the
            USA. <br /> <br />
            After having established these factors, we create a detailed
            description that includes user journeys, tech stack, project
            investment, and timeline. Contact us with your requirement to get
            the App development cost.
          </>
        ),
      },

      {
        faqTitle: 'How long does it take to build an Application?',
        faqPara: (
          <>
            The exact time to build an application depends on its features, the
            screen sizes, and the frameworks used. There is a huge process
            involved in developing web apps and mobile apps. But it normally
            takes 2 months to around 4–5 months depending on app developers,
            functionalities of the app, platform and much more.
          </>
        ),
      },
    ],
    contactHeading: 'Have more questions?',
    contactPara:
      'Let us know and our experts will get in touch with you ASAP. ',
    supportImg: images.contactLadySm,
    buttonName: 'Talk to our experts',
    buttonLink: '/contact/',
  },
  featureResourceProps: {
    heading: 'Featured Resources',
    featureContents: [
      {
        blogLink:
          'https://www.simform.com/blog/how-to-build-messaging-app-whatsapp-telegram-slack/',
        featurecardTitle:
          'How to Build a Messaging App like Whatsapp, Telegram, Slack',
      },
      {
        blogLink: 'https://www.simform.com/blog/how-make-app-like-airbnb/',
        featurecardTitle:
          'How to Make an app like Airbnb : Develop your own Vacation Rental App',
      },
      {
        blogLink:
          'https://www.simform.com/blog/healthcare-app-development-features-types/',
        featurecardTitle:
          'Healthcare App Development: Top Trends, Features & Types',
      },
    ],
  },
}
